import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Logo from "../../assets/img/Logo.webp";
import MenuIcon from "@mui/icons-material/Menu";
import "./style.css";
import { useState } from "react";
import { Link , NavLink} from "react-router-dom";
const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };  
  
  
  return (
    <>
      <AppBar position="static" className="navbr">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
           <NavLink to="/"><img src={Logo} className="logowd" /> </NavLink> 

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} justifyContent="flex-end">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="#000"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                 anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem>
                <NavLink to="/" className="navtextd"  ><Typography textAlign="center">Tax Calculator</Typography></NavLink>
                  
                </MenuItem>
                <MenuItem>
                  
                <NavLink to="/tax-updates" className="navtextd"><Typography textAlign="center">Tax Updates</Typography></NavLink>
           
                </MenuItem>
                <MenuItem>
     
                <NavLink to="/faqs" className="navtextd"><Typography textAlign="center">FAQs</Typography></NavLink>
                </MenuItem>
              </Menu>
            </Box>

            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              className="navtext"
            >
              <NavLink to="/" className="navtextd"  ><Button  sx={{ my: 2, color: "black", display: "block", textDecoration: "none" }}>
              Tax Calculator
              </Button> </NavLink>
              <NavLink to="/tax-updates" className="navtextd"><Button sx={{ my: 2, color: "#191D24", display: "block" }}>
                Tax Updates
              </Button></NavLink>
              <NavLink to="/faqs" className="navtextd"><Button sx={{ my: 2, color: "#191D24", display: "block" }}>
                FAQs
              </Button></NavLink>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
   
    </>
  );
};

export default Header;
