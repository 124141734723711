import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import "./style.css";
import { ReactComponent as Help } from "../../assets/icn/help.svg";
import NumberFormat, { NumericFormat } from "react-number-format";
import { PieChart } from "@mui/icons-material";
import { useEffect, useState } from "react";
import _ from "lodash";
import Lmark from "../../assets/icn/Lmark.svg";
import { Controller, useForm, useWatch } from "react-hook-form";

const Calculatorinput = () => {
  const { control, handleSubmit: handleFormSubmit } = useForm();
  const [secondary, setSecondary] = useState("");
  const [result, setResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const secondaryAmountValue = useWatch({ control, name: 'secondaryAmount' });

  const calculateAPIT = (amount) => {
    return amount * 0.36;
  };
  useEffect(() => {
    const apitResult = calculateAPIT(Number(secondary));
    setResult(apitResult.toFixed(2));
  }, [secondary]);

  const handleSave = (data) => {
    console.log(data);
    setSecondary(data?.secondaryAmount);
    setShowResult(true);
  };

 

  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow1"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardccb2"
          container
          direction="column"
          justifyContent="center"
        >
          <Grid className="cardtop" textAlign="left">
            <span>APIT calculation</span>
          </Grid>
          <Grid
            className="cardbottomcb5"
            container
            direction="row"
            justifyContent="center"
            
          >
            <form>
              <Grid item container md={12} xs={12}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <Grid container md={7} xs={12}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      className="aptcaltitle"
                    >
                      <span className="caltit1cb2">
                        Your monthly earnings from your secondary employment
                      </span>
                    </Grid>

                    <Grid container direction="row" className="aptcaltitle2">
                      <span className="caltit2cb2"> (April to March) </span>

                      <Tooltip
                        title="Your monthly remuneration"
                        className="tolip"
                      >
                        <IconButton>
                          <Help />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    md={5}
                    xs={12}
                    justifyContent="flex-end"
                    className="aptcal"
                  >
                    <Controller
                      name="secondaryAmount"
                      control={control}
                      render={({ field }) => (
                        <NumericFormat
                          thousandSeparator={true}
                          prefix={"Rs."}
                          className="amount"
                          placeholder="Rs."
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value);
                            console.log(values);
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                textAlign="center"
                className="bttngrid"
              >
                <Button
                  variant="contained"
                  className="calbtncb5"
                  onClick={handleFormSubmit(handleSave)}
                  disabled={_.isEmpty(secondaryAmountValue) }
                >
                  Calculate
                </Button>
              </Grid>
            </form>
            <Grid
              container
              item
              md={12}
              xs={12}
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              {showResult ? (
                <Grid item>
                  <div className="resultbox2">
                    <span className="apitt">Your APIT is</span>

                    <NumericFormat
                      displayType="text"
                      prefix={"Rs."}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={result !== 0 ? result : 0}
                      className="amountt"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Calculatorinput;
