import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import "./style.css";
import Logo from "../../assets/img/Logo.webp";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Grid
        container
        className="footermain"
        direction="row"
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid
          container
          md={6}
          xs={12}
          sm={6}
          direction="row"
          justifyContent="space-between"
          className="footermain2"
        >
          <Grid
            container
            direction="column"
            md={12}
            xs={12}
            className="footerlogorow"
          >
            <NavLink to="/">
              {" "}
              <img src={Logo} className="footerlogo" />{" "}
            </NavLink>
          </Grid>
          <Grid
            container
            md={12}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={5}
            className="footermenu"
          >
            <Grid item>
              <NavLink to="/" className="navtextd">
                <span className="footernav">Tax Calculator</span>
              </NavLink>
            </Grid>

            <Grid item>
              <NavLink to="/tax-updates" className="navtextd">
                <span className="footernav">Tax Updates</span>
              </NavLink>
            </Grid>

            <Grid item>
              <NavLink to="/faqs" className="navtextd">
                <span className="footernav">FAQs</span>
              </NavLink>
            </Grid>

            <Grid item>
              <NavLink to="https://adayama.lk/blog/" className="navtextd">
                <span className="footernav">Blog</span>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          md={6}
          xs={12}
          sm={6}
          justifyContent="flex-end"
          alignItems="center"
          className="footermain1"
        >
          <Grid
            container
            md={12}
            xs={12}
            justifyContent="flex-end"
            direction="column"
            textAlign="end"
            className="sright"
          >
            <span className="subtitle">Subscribe to tax updates</span>
            <form className="submit">
              <TextField
                label="Enter your phone/email"
                className="subfeild"
                InputLabelProps={{ className: "customLabel" }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    background: "#fff",
                  },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="subbtn"
              >
                Subscribe
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="footerborderp">
        <div className="footerborder" />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        className="footerbttom"
      >
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          md={6}
          xs={12}
          sm={6}
          className="copyr"
        >
          <span className="footerbttxt">
            © 2024 Divit Advisory. All rights reserved.
          </span>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="flex-end"
          md={6}
          xs={12}
          sm={6}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            spacing={3}
            className="bottomnav"
          >
            <Grid item>
              <span className="footerbttxt">Terms</span>
            </Grid>
            <Grid item>
              
                {" "}
                <span className="footerbttxt"> <Link to="/privacy-policy" style={{textDecoration:"none",color:"#667085",fontSize:"14px !important",fontWeight:"400"}}>Privacy Policy  </Link></span>
            
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
