import { Button, Grid } from "@mui/material";
import "./style.css";
import Del from "../../assets/icn/Dol.svg";
import Ao from "../../assets/icn/Lettemo.svg";
import Ao2 from "../../assets/icn/Lettemo2.svg";
import Ao3 from "../../assets/icn/Lettermo3.svg";
import Ao4 from "../../assets/icn/Lettermo4.svg";

const Informationbox = ({ showMessageBox }) => {
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardm"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>How this is calculated</span>
          </Grid>
          <Grid
            className="cardbottomcb2"
            container
            direction="row"
            justifyContent="space-between"
            spacing={{ md: 4 }}
          >
            <Grid
              item
              md={12}
              xs={12}
              textAlign="center"
              className="maincrdcb2"
              spacing={10}
            >
              <Grid direction="row" spacing={4} className="inncrd2cb3">
                

                <Grid
                  container
                  gap={{sm:10, md:0}}
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                >
                  <Grid item md={9} xs={12} sm={5} textAlign="left">
                    <span className="tbtitle">Category</span>
                  </Grid>

                  <Grid item md={3} xs={12} sm={5} textAlign="left">
                    <span className="tbtitle">Tax rate</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  gap={{sm:10, md:0}}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={9}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">If the total monthly income from your primary and secondary employment is less than Rs. 100,000</span>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate</span>
                      </span>{" "}
                      You don't have to pay APIT
                    </span>
                  </Grid>
                </Grid>

              

                <Grid
                  item
                  md={12}
                  container
                  gap={{sm:10, md:0}}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2c-b3"
                >
                  <Grid
                    item
                    md={9}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">If the total monthly income from your primary and secondary employment is more than Rs. 100,000</span>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate</span>
                      </span>{" "}
                      See tax rates as below
                    </span>
                  </Grid>
                </Grid>

               
              </Grid>

              <Grid direction="row" spacing={4} className="inncrd3cb3">
                <Grid
                  container
                  direction="row"
                  justifyContent="left"
                  className="tabletitlegrid"
                >
                  <span className="tabletitle">
                  APIT is deductible on the total earnings from your secondary employment
                  </span>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                >
                  <Grid item md={6} xs={12} sm={6} textAlign="left">
                    <span className="tbtitle">Monthly earnings from your primary employment</span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={6} textAlign="left">
                    <span className="tbtitle">Tax rate on earnings from secondary employment</span>
                  </Grid>
                </Grid>
                

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Up to </span>
                      </span>
                     <span className="fontweight">Up to</span>  Rs. 100,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate </span>
                      </span>{" "}
                      <span >6%</span>
                    </span>
                  </Grid>
                </Grid>

                
                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>
                      Rs. 100,001 <span className="fontweight">to</span>  Rs. 141,66
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate </span>
                      </span>{" "}
                      <span >12%</span>
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>
                      Rs. 141,668 <span className="fontweight">to</span>  Rs. 183,333
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate </span>
                      </span>{" "}
                      <span >18%</span>
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>
                      Rs. 183,334 <span className="fontweight">to</span>  Rs. 225,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate </span>
                      </span>{" "}
                      <span >24%</span>
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>
                      Rs. 225,001 <span className="fontweight">to</span> Rs. 266,667
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate </span>
                      </span>{" "}
                      <span >30%</span>
                    </span>
                  </Grid>
                </Grid>

                

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2c-b3"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">More than</span> Rs. 266,668
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Tax rate </span>
                      </span>{" "}
                      <span >36%</span>
                    </span>
                  </Grid>
                </Grid>

                
              </Grid>

              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Informationbox;
