import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Grid,
  Input,
  InputAdornment,
} from "@mui/material";
import "./style.css";
import Num1 from "../../assets/icn/1.svg";
import Num2 from "../../assets/icn/2.svg";
import Num3 from "../../assets/icn/3.svg";
import Num4 from "../../assets/icn/4.svg";
import { ReactComponent as Sl } from "../../assets/icn/sl.svg";
import { ReactComponent as Wr } from "../../assets/icn/Wr.svg";
import { ReactComponent as Sl2 } from "../../assets/icn/Sl2.svg";
import { ReactComponent as Wr2 } from "../../assets/icn/Wr2.svg";
import { ReactComponent as Bag } from "../../assets/icn/Bag.svg";
import { ReactComponent as Bgh } from "../../assets/icn/Bgh.svg";
import { ReactComponent as Cash } from "../../assets/icn/Cash.svg";
import { ReactComponent as Cashb } from "../../assets/icn/Cashb.svg";
import { ReactComponent as Mail } from "../../assets/icn/Mail.svg";
import { ReactComponent as Mailb } from "../../assets/icn/Mailb.svg";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import Header from "../../components/header";
import Hero from "../../components/header/hero";
import Footer from "../../components/footer";
import Combination1 from "../combination1";
import Combination2 from "../combination2";
import Combination3 from "../combination3";
import Combination4 from "../combination4";
import Combination5 from "../combination5";
import Combination6 from "../combination6";
import Combination7 from "../combination7";
import { FemaleSharp } from "@mui/icons-material";
import OtherCombination from "../otherCombination/otherCombination";
import {animateScroll as scroll } from 'react-scroll';


const Introduction = () => {
  const { control, handleSubmit: handleIntroSubmit } = useForm();
  const [locationChecked, setLocationChecked] = useState(false);
  const [otherLocationChecked, setOtherLocationChecked] = useState(false);
  const [sriLankan, setSriLankan] = useState(false);
  const [otherNationality, setOtherNationality] = useState(false);
  const [primaryChecked, setPrimaryChecked] = useState(false);
  const [secondaryChecked, setSecondaryChecked] = useState(false);
  const [monthlySalaryChecked, setMonthlySalaryChecked] = useState(false);
  const [lumpSumChecked, setLumpSumChecked] = useState(false);
  const [onceForAllChecked, setOnceForAllChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isNextClicked, setIsNextClicked] = useState(false);

  const handleMonthlySalaryChange = () => {
    setMonthlySalaryChecked(true);
    setLumpSumChecked(false);
    setOnceForAllChecked(false);
    setAllChecked(false);
  };

  const handleLumpSumChange = () => {
    setMonthlySalaryChecked(false);
    setLumpSumChecked(true);
    setOnceForAllChecked(false);
    setAllChecked(false);
  };

  const handleOnceForAllChange = () => {
    setMonthlySalaryChecked(false);
    setLumpSumChecked(false);
    setOnceForAllChecked(true);
    setAllChecked(false);
  };

  const handleAllChange = () => {
    setMonthlySalaryChecked(false);
    setLumpSumChecked(false);
    setOnceForAllChecked(false);
    setAllChecked(true);
  };

  const handlePrimaryChange = () => {
    setPrimaryChecked(true);
    setSecondaryChecked(false); // Ensure the other checkbox is unchecked
  };

  const handleSecondaryChange = () => {
    setPrimaryChecked(false); // Ensure the primary checkbox is unchecked
    setSecondaryChecked(true);
  };

  const handleLocationChange = () => {
    setLocationChecked(true);
    setOtherLocationChecked(false); // Ensure the other checkbox is unchecked
  };

  const handleNotInSriLanka = () => {
    setLocationChecked(false); // Ensure the location checkbox is unchecked
    setOtherLocationChecked(true);
  };

  const handleCitizenChange = () => {
    setSriLankan(true);
    setOtherNationality(false); // Ensure the other checkbox is unchecked
  };

  const handleNationality = () => {
    setSriLankan(false); // Ensure the location checkbox is unchecked
    setOtherNationality(true);
  };

  const handleReset = () => {
    window.location.reload();
  };
  const anyCheckboxSelected = () => {
    // Check if any checkbox is selected
    return (
      locationChecked ||
      otherLocationChecked ||
      sriLankan ||
      otherNationality ||
      primaryChecked ||
      secondaryChecked ||
      monthlySalaryChecked ||
      lumpSumChecked ||
      onceForAllChecked ||
      allChecked
    );
  };
  const scrollMore = () => {
    scroll.scrollMore(600); // Scrolling an additional 100px from the current scroll position.
  };
  const handleSave = (data) => {
    setIsNextClicked(true);
    setSubmitted(true);
    scrollMore()
  };

  const conditionOne = () => {
    return (
      submitted &&
      locationChecked &&
      sriLankan &&
      primaryChecked &&
      monthlySalaryChecked /* add all your checked values here */
    );
  };
  const conditionTwo = () => {
    return (
      submitted &&
      sriLankan &&
      locationChecked &&
      primaryChecked &&
      lumpSumChecked /* add all your checked values here */
    );
  };

  const conditionThree = () => {
    return (
      submitted &&
      locationChecked &&
      primaryChecked &&
      sriLankan &&
      onceForAllChecked /* add all your checked values here */
    );
  };

  const conditionFour = () => {
    return (
      submitted &&
      allChecked &&
      sriLankan &&
      locationChecked &&
      secondaryChecked /* add all your checked values here */
    );
  };

  const conditionFive = () => {
    return (
      submitted &&
      otherLocationChecked &&
      sriLankan &&
      secondaryChecked &&
      allChecked /* add all your checked values here */
    );
  };

  const conditionSix = () => {
    return (
      submitted &&
      monthlySalaryChecked &&
      otherLocationChecked &&
      otherNationality &&
      primaryChecked /* add all your checked values here */
    );
  };

  const conditionSeven = () => {
    return (
      submitted &&
      lumpSumChecked &&
      otherNationality &&
      otherLocationChecked &&
      primaryChecked /* add all your checked values here */
    );
  };

  const conditionEight = () => {
    return (
      submitted &&
      otherLocationChecked &&
      sriLankan &&
      primaryChecked &&
      monthlySalaryChecked /* add all your checked values here */
    );
  };

  const conditionNine = () => {
    return (
      submitted &&
      sriLankan &&
      otherLocationChecked &&
      primaryChecked &&
      lumpSumChecked /* add all your checked values here */
    );
  };

  const conditionTen = () => {
    return (
      submitted &&
      otherLocationChecked &&
      primaryChecked &&
      sriLankan &&
      onceForAllChecked /* add all your checked values here */
    );
  };

  const conditionEleven = () => {
    return (
      submitted &&
      allChecked &&
      otherNationality &&
      locationChecked &&
      secondaryChecked /* add all your checked values here */
    );
  };

  const conditionTwelve = () => {
    return (
      submitted &&
      otherLocationChecked &&
      otherNationality &&
      secondaryChecked &&
      allChecked /* add all your checked values here */
    );
  };

  const conditionThirteen = () => {
    return (
      submitted &&
      monthlySalaryChecked &&
      otherLocationChecked &&
      otherNationality &&
      secondaryChecked /* add all your checked values here */
    );
  };

  const conditionFourteen = () => {
    return (
      submitted &&
      locationChecked &&
      otherNationality &&
      primaryChecked &&
      monthlySalaryChecked 
    );
  };
  const showDefaultComponent = () => {
    return (
      submitted &&
      !(
        conditionOne() ||
        conditionTwo() ||
        conditionThree() ||
        conditionFour() ||
        conditionFive() ||
        conditionSix() ||
        conditionSeven() ||
        conditionEight() ||
        conditionNine() ||
        conditionTen() ||
        conditionEleven() ||
        conditionTwelve() ||
        conditionThirteen() ||
        conditionFourteen()
      )
    );
  };
  return (
    <>
      {<Header />}

      <Hero />
      <Container maxWidth="xl">
        <Grid
          Grid
          item
          xs={12}
          md={12}
          className="mainrow"
          direction="column"
          alignItems="stretch"
          id="intro"
        >
          <Grid
            className="maincard"
            container
            direction="column"
            justifyContent="flex-start"
          >
            <form>
              <Grid className="cardtop" textAlign="left">
                <span>First, tell us a bit about you</span>
              </Grid>
              <Grid
                className="cardbottom"
                container
                direction="row"
                justifyContent="space-between"
                spacing={8}
              >
                <Grid item md={6} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="titlegrid"
                  >
                    <img src={Num1} className="cardimg" />
                    <span className="cardtitle">
                      Do you currently live in Sri Lanka?
                    </span>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="sincol"
                  >
                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="location"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={locationChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handleLocationChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Sl className="svgn" />}
                              checkedIcon={<Sl className="svga" />}
                            />
                          )}
                        />
                        <span
                          className={
                            !locationChecked ? "btntext" : "btntext-active"
                          }
                        >
                          Yes, I do
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="notSriLanka"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={otherLocationChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handleNotInSriLanka(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Wr className="svgn" />}
                              checkedIcon={<Wr className="svga" />}
                            />
                          )}
                        />
                        <span
                          className={
                            !otherLocationChecked ? "btntext" : "btntext-active"
                          }
                        >
                          No, I don’t
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="titlegrid"
                  >
                    <img src={Num2} className="cardimg" />
                    <span className="cardtitle">
                      Are you a Sri Lankan citizen?
                    </span>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="sincol"
                  >
                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="citizen"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={sriLankan}
                              onChange={(e) => {
                                field.onChange(e);
                                handleCitizenChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Sl2 className="svgn" />}
                              checkedIcon={<Sl2 className="svga" />}
                            />
                          )}
                        />
                        <span
                          className={!sriLankan ? "btntext" : "btntext-active"}
                        >
                          Yes, I am
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="nationality"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={otherNationality}
                              onChange={(e) => {
                                field.onChange(e);
                                handleNationality(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Wr2 className="svgn" />}
                              checkedIcon={<Wr2 className="svga" />}
                            />
                          )}
                        />

                        <span
                          className={
                            !otherNationality ? "btntext" : "btntext-active"
                          }
                        >
                          No, I'm not
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="titlegrid"
                  >
                    <img src={Num3} className="cardimg" />
                    <span className="cardtitle">
                      How would you categorize your employment?
                    </span>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="sincol"
                  >
                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="primary"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={primaryChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handlePrimaryChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Bag className="svgn" />}
                              checkedIcon={<Bag className="svga" />}
                            />
                          )}
                        />
                        <span
                          className={
                            !primaryChecked ? "btntext" : "btntext-active"
                          }
                        >
                          Primary
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="secondary"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={secondaryChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handleSecondaryChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Bgh className="svgn" />}
                              checkedIcon={<Bgh className="svga" />}
                            />
                          )}
                        />

                        <span
                          className={
                            !secondaryChecked ? "btntext" : "btntext-active"
                          }
                        >
                          Secondary
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12} direction="row" alignSelf="flex-end">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="titlegrid"
                  >
                    <img src={Num4} className="cardimg" />
                    <span className="cardtitle">
                      What type of earnings do you receive?
                    </span>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className="sincol"
                    gap={{ xs: 4, md: 0 }}
                  >
                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="monthlySalary"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={monthlySalaryChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handleMonthlySalaryChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Cash className="svgn" />}
                              checkedIcon={<Cash className="svga" />}
                            />
                          )}
                        />

                        <span
                          className={
                            !monthlySalaryChecked ? "btntext" : "btntext-active"
                          }
                        >
                          Monthly Salary
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="lumpsum"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={lumpSumChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handleLumpSumChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Cashb className="svgn" />}
                              checkedIcon={<Cashb className="svga" />}
                            />
                          )}
                        />

                        <span
                          className={
                            !lumpSumChecked ? "btntext" : "btntext-active"
                          }
                        >
                          Lump sum
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="onceForAll"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={onceForAllChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                console.log(e.target.checked);
                                handleOnceForAllChange();
                                setSubmitted(false);
                              }}
                              icon={<Mail className="svgn" />}
                              checkedIcon={<Mail className="svga" />}
                            />
                          )}
                        />

                        <span
                          className={
                            !onceForAllChecked ? "btntext" : "btntext-active"
                          }
                        >
                          Once & for all
                          <br />
                          payments
                        </span>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction="column"
                        textAlign="center"
                        className="incomp"
                      >
                        <Controller
                          name="all"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              checked={allChecked}
                              onChange={(e) => {
                                field.onChange(e);
                                handleAllChange(e.target.checked);
                                setSubmitted(false);
                              }}
                              icon={<Mailb className="svgn" />}
                              checkedIcon={<Mailb className="svga" />}
                            />
                          )}
                        />

                        <span
                          className={!allChecked ? "btntext" : "btntext-active"}
                        >
                          All
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className="cardend"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={{ md: 6, xs: 0 }}
              >
                <Button
                  className="bottombtn1"
                  variant="outlined"
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  className={!anyCheckboxSelected()? "disabledBtn":"bottombtn2"}
                  variant="contained"
                  onClick={handleIntroSubmit(handleSave)}
                  disabled={!anyCheckboxSelected()}
                >
                  next
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        {conditionOne() ? <Combination1 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionTwo() ? <Combination2 /> : null}
      </Container>

      <Container maxWidth="xl">
        {conditionThree() ? <Combination3 /> : null}
      </Container>

      <Container maxWidth="xl">
        {conditionFour() ? <Combination4 /> : null}
      </Container>

      <Container maxWidth="xl">
        {conditionFive() ? <Combination5 /> : null}
      </Container>

      <Container maxWidth="xl">
        {conditionSix() ? <Combination6 /> : null}
      </Container>

      <Container maxWidth="xl">
        {conditionSeven() ? <Combination7 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionEight() ? <Combination1 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionNine() ? <Combination2 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionTen() ? <Combination3 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionEleven() ? <Combination4 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionTwelve() ? <Combination5 /> : null}
      </Container>
      <Container maxWidth="xl">
        {conditionThirteen() ? <Combination6 /> : null}
      </Container>

      <Container maxWidth="xl">
        {conditionFourteen() ? <Combination1 /> : null}
      </Container>

      <Container maxWidth="xl">
        {showDefaultComponent() && <OtherCombination />}
      </Container>
      <div className="footer">
        <Container maxWidth="xl">
          <Footer />
        </Container>
      </div>
    </>
  );
};

export default Introduction;
