import { Controller, useForm } from "react-hook-form";
import Calculatorinput from "./calculatorinput";
import Informationbox from "./informationbox";
import _, { result } from "lodash";
import { useEffect, useState } from "react";

const Combination3 = () => {
  const { control, handleSubmit: handleFormSubmit } = useForm();
  const [result, setResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const [commutationOfPension, setCommutationOfPension] = useState(0);
  const [gratuity, setGratuity] = useState(0);
  const [compensationLossOfOffice, setCompensationLossOfOffice] = useState(0);
  const [compensationLossOfOfficeOther, setCompensationLossOfOfficeOther] =
    useState(0);
  const [withdrawalETF, setWithdrawalETF] = useState(0);
  const [paymentForProvidentFund, setPaymentForProvidentFund] = useState(0);
  const [anyOtherPayment, setAnyOtherPayment] = useState(0);
  const [retirementNonCashBenefits, setRetirementNonCashBenefits] = useState(0);

  const [apitOne, setApitOne] = useState(0);
  const [apitTwo, setApitTwo] = useState(0);
  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalThree, setTotalThree] = useState(0);

  const calculateValue = (
    commutationOfPension,
    gratuity,
    compensationLossOfOffice,
    compensationLossOfOfficeOther,
    withdrawalETF,
    paymentForProvidentFund,
    anyOtherPayment,
    retirementNonCashBenefits
  ) => {
    let result = 0;
    commutationOfPension = Number(commutationOfPension);
    gratuity = Number(gratuity);
    compensationLossOfOffice = Number(compensationLossOfOffice);
    compensationLossOfOfficeOther = Number(compensationLossOfOfficeOther);
    withdrawalETF = Number(withdrawalETF);
    paymentForProvidentFund = Number(paymentForProvidentFund);
    anyOtherPayment = Number(anyOtherPayment);
    retirementNonCashBenefits = Number(retirementNonCashBenefits);

    let sumMain =
      commutationOfPension +
      gratuity +
      compensationLossOfOffice +
      withdrawalETF;
    console.log(sumMain);
    if (sumMain > 5000000) {
      let difference = sumMain - 5000000;
      result += difference * 0.12;
    }

    let sumOther =
      compensationLossOfOfficeOther +
      paymentForProvidentFund +
      anyOtherPayment +
      retirementNonCashBenefits;
    result += sumOther * 0.36;
    return result;
  };
  const calculateTotalOne = (
    commutationOfPension,
    gratuity,
    compensationLossOfOffice,
    withdrawalETF
  ) => {
    console.log(
      commutationOfPension,
      gratuity,
      compensationLossOfOffice,
      withdrawalETF
    );
    const convertToNumberOrZero = (value) =>
      _.isNaN(value) || !_.isNumber(value) ? 0 : Number(value);

    // Apply conversion and sum
    const total =
      convertToNumberOrZero(commutationOfPension) +
      convertToNumberOrZero(gratuity) +
      convertToNumberOrZero(compensationLossOfOffice) +
      convertToNumberOrZero(withdrawalETF);

    console.log(total);
    return total;
  };
  const calculateAPIT = (amount, percentage) => {
    const convertToNumberOrZero = (value) =>
      _.isNaN(value) || !_.isNumber(value) ? 0 : Number(value);

    // Calculate APIT (Amount * Percentage)
    const apit = (convertToNumberOrZero(amount) * percentage) / 100;
    return apit;
  };
  const calculateTotalAPIT = (apitOne, apitTwo) => {
    return apitOne + apitTwo;
  };
  useEffect(() => {
    const totalAPITResult = calculateTotalAPIT(apitOne, apitTwo);
    setTotalThree(totalAPITResult);
  }, [apitOne, apitTwo]);
  useEffect(() => {
    const answerOne = calculateTotalOne(
      Number(commutationOfPension),
      Number(gratuity),
      Number(compensationLossOfOffice),
      Number(withdrawalETF)
    );
    setTotalOne(answerOne);
  }, [commutationOfPension, gratuity, compensationLossOfOffice, withdrawalETF]);
  useEffect(() => {
    const answerTwo = calculateTotalOne(
      Number(paymentForProvidentFund),
      Number(retirementNonCashBenefits),
      Number(compensationLossOfOfficeOther),
      Number(anyOtherPayment)
    );
    setTotalTwo(answerTwo);
  }, [
    paymentForProvidentFund,
    retirementNonCashBenefits,
    compensationLossOfOfficeOther,
    anyOtherPayment,
  ]);

  useEffect(() => {
    const apitOneAns =
      Number(totalOne) > 5000000 ? calculateAPIT(totalOne, 12).toFixed(2) : 0;
    setApitOne(apitOneAns);
  }, [totalOne]);

  useEffect(() => {
    const apitAnsTwo = Number(calculateAPIT(totalTwo, 36).toFixed(2));
    setApitTwo(apitAnsTwo);
  }, [totalTwo]);

  useEffect(() => {
    const answer = calculateValue(
      commutationOfPension,
      gratuity,
      compensationLossOfOffice,
      compensationLossOfOfficeOther,
      withdrawalETF,
      paymentForProvidentFund,
      anyOtherPayment,
      retirementNonCashBenefits
    );
    console.log(answer);
    setResult(answer);
  }, [
    commutationOfPension,
    gratuity,
    compensationLossOfOffice,
    compensationLossOfOfficeOther,
    withdrawalETF,
    paymentForProvidentFund,
    anyOtherPayment,
    retirementNonCashBenefits,
  ]);

  const onSubmit = (data) => {
    console.log(data);
    setShowResult(true);
    setAnyOtherPayment(data?.anyOtherPayment || 0);
    setCommutationOfPension(data?.commutionOfpension || 0);
    setCompensationLossOfOffice(data?.compensationLossOfOffice || 0);
    setCompensationLossOfOfficeOther(data?.compensationLossOfOfficeOther || 0);
    setGratuity(data?.gratuity || 0);
    setPaymentForProvidentFund(data?.paymentForProvidentFund || 0);
    setRetirementNonCashBenefits(data?.retirmentNonCashBenefits || 0);
    setWithdrawalETF(data?.withdrawalOfETF || 0);
  };

  return (
    <>
      <Calculatorinput
        handleSave={handleFormSubmit(onSubmit)}
        showResult={showResult}
        result={result}
        control={control}
        Controller={Controller}
      />

      <Informationbox
        apitOne={apitOne}
        apitTwo={apitTwo}
        totalOne={totalOne}
        totalTwo={totalTwo}
        totalThree={totalThree}
        lumpSumPension={commutationOfPension}
        gratuity={gratuity}
        compensationForLossOfOffice={compensationLossOfOffice}
        compensationLossOfOfficeOther={compensationLossOfOfficeOther}
        withdrawalETF={withdrawalETF}
        paymentForProvidentFund={paymentForProvidentFund}
        otherPayment={anyOtherPayment}
        nonCashBenefits={retirementNonCashBenefits}
      />
    </>
  );
};

export default Combination3;
