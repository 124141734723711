import { Route, Routes } from "react-router-dom";
import ComingSoon from "./pages/commingSoon";
import Introduction from "./pages/intro";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/privacyPolicy";
import TagManager from "react-gtm-module";
import Smartlook from 'smartlook-client';


Smartlook.init('3a05e09686b96920d26f2307d9bce0800ba6a950')


const TRACKING_ID = "G-Y376MKWBKG";
ReactGA.initialize(TRACKING_ID);

const GTM_ID = "GTM-TK9P8ZXD";

function App() {

  useEffect(() =>{
   
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, 
    
    title: "Adayama" });

    TagManager.initialize({ gtmId: GTM_ID });


  }, []);

  
  return (
    <>
      <Routes>
        <Route path="/" element={<Introduction />} />
        <Route path="/tax-updates" element={<ComingSoon />} />
        <Route path="/faqs" element={<ComingSoon />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      </Routes>
    </>
  );
}

export default App;
