import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import "./style.css";
import { ReactComponent as Help } from "../../assets/icn/help.svg";
import NumberFormat, { NumericFormat } from "react-number-format";
import { PieChart } from "@mui/icons-material";
import { useEffect, useState } from "react";
import _ from "lodash";
import Lmark from "../../assets/icn/Lmark.svg";
import { Controller, useForm, useWatch } from "react-hook-form";

const Calculatorinput = () => {
  const { control, handleSubmit: handleFormSubmit } = useForm();
  const [secondary, setSecondary] = useState("");
  const [result, setResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const monthlySalaryValue = useWatch({ control, name: 'monthlySalary' });

  const calculateAPIT = (monthlySalary) => {
    if (monthlySalary > 208333) {
      return monthlySalary * 0.36 - 37500;
    } else if (monthlySalary > 166667) {
      return monthlySalary * 0.3 - 25000;
    } else if (monthlySalary > 125000) {
      return monthlySalary * 0.24 - 15000;
    } else if (monthlySalary > 83333) {
      return monthlySalary * 0.18 - 7500;
    } else if (monthlySalary > 41667) {
      return monthlySalary * 0.12 - 2500;
    } else if (monthlySalary > 0) {
      return monthlySalary * 0.06;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const apitResult = calculateAPIT(Number(secondary));
    setResult(apitResult.toFixed(2));
  }, [secondary]);

  const handleSave = (data) => {
    console.log(data);
    setSecondary(data?.monthlySalary);
    setShowResult(true);
  };

  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow1"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardc"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>APIT calculation</span>
          </Grid>
          <Grid
            className="cardbottom"
            container
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item md={12} xs={12} textAlign="center">
              <span className="caltit1">Enter your monthly earnings</span>
              <span className="caltit2"> (all inclusive) </span>

              <Tooltip title="Your monthly remuneration" className="tolip">
                <IconButton>
                  <Help />
                </IconButton>
              </Tooltip>

              <img src={Lmark} className="eicon" />
            </Grid>

            <Grid item md={12} xs={12} textAlign="center">
              <Controller
                name="monthlySalary"
                control={control}
                render={({ field }) => (
                  <NumericFormat
                    thousandSeparator={true}
                    prefix={"Rs."}
                    className="amount"
                    placeholder="Rs."
                    onValueChange={(values, sourceInfo) => {
                      field.onChange(values.value);
                      console.log(values);
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={12} xs={12} textAlign="center">
              <Button
                variant="contained"
                className="calbtn"
                onClick={handleFormSubmit(handleSave)}
                disabled={_.isEmpty(monthlySalaryValue) }
              >
                Calculate
              </Button>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              {showResult ? (
                <Grid item>
                  <div className="resultbox">
                    <span className="apitt">Your APIT is</span>

                    <NumericFormat
                      displayType="text"
                      prefix={"Rs."}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={result !== 0 ? result : 0}
                      className="amountt"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Calculatorinput;
