import { Button, Grid } from "@mui/material";
import "./style.css";
import Del from "../../assets/icn/Dol.svg";
import Ao from "../../assets/icn/Lettemo.svg";
import Ao2 from "../../assets/icn/Lettemo2.svg";
import Ao3 from "../../assets/icn/Lettermo3.svg";
import Ao4 from "../../assets/icn/Lettermo4.svg";
import { NumericFormat } from "react-number-format";
import { useState } from "react";

const Informationbox = ({
  apitOne,
  apitTwo,
  totalOne,
  totalTwo,
  totalThree,
  lumpSumPension,
  gratuity,
  compensationForLossOfOffice,
  compensationLossOfOfficeOther,
  withdrawalETF,
  paymentForProvidentFund,
  otherPayment,
  nonCashBenefits,
}) => {
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardm"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>How this is calculated</span>
          </Grid>
          <Grid
            className="cardbottomcb2"
            container
            direction="row"
            justifyContent="space-between"
            spacing={{ md: 4 }}
          >
            <Grid
              item
              md={12}
              xs={12}
              textAlign="center"
              className="maincrdcb2"
              spacing={10}
            >
              <Grid direction="row" spacing={4} className="inncrd2cb3">
                <Grid
                  container
                  direction="row"
                  justifyContent="left"
                  className="tabletitlegrid"
                >
                  <span className="tabletitle">
                    If the total of these payments is more than Rs.{" "}
                    <span className="colorblue">5,000,000</span> the APIT
                    deductible will be <span className="coloror">12%</span> of
                    the excess amount
                  </span>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                >
                  <Grid item md={8} xs={12} sm={7} textAlign="left">
                    <span className="tbtitle">Type of payment</span>
                  </Grid>

                  <Grid item md={4} xs={12} sm={5} textAlign="left">
                    <span className="tbtitle">Value</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Lump sum pension</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={lumpSumPension}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Gratuity</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={gratuity}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Compensation for loss of office
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={compensationForLossOfOffice}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2cb3"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Withdrawal of ETF</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={withdrawalETF}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2c-b3"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Total payments</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={totalOne}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2c-b3"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="blulet2">APIT deducted</span>
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={apitOne}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>

              <Grid direction="row" spacing={4} className="inncrd3cb3">
                <Grid
                  container
                  direction="row"
                  justifyContent="left"
                  className="tabletitlegrid"
                >
                  <span className="tabletitle">
                    The APIT deductible on these payments will be{" "}
                    <span className="coloror">36%</span>
                  </span>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                >
                  <Grid item md={8} xs={12} sm={7} textAlign="left">
                    <span className="tbtitle">Type of payment</span>
                  </Grid>

                  <Grid item md={4} xs={12} sm={5} textAlign="left">
                    <span className="tbtitle">Value</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Compensation for loss of office (other)
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={compensationLossOfOfficeOther}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Payment for provident fund</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={paymentForProvidentFund}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Any other payment</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={otherPayment}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2cb3"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      Retirement non cash benefits
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={nonCashBenefits}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2c-b3"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">Total payments</span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={totalTwo}
                      />
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2c-b3"
                >
                  <Grid
                    item
                    md={8}
                    xs={12}
                    sm={7}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="blulet2">APIT deducted</span>
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={5}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={apitTwo}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>

              <Grid direction="row" spacing={4} className="inncrd3cb3">
                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebgcb3"
                >
                  <Grid item md={8} xs={12} sm={7} textAlign="left">
                    <span className="tbtitlecb3">Total APIT</span>
                  </Grid>

                  <Grid item md={4} xs={12} sm={5} textAlign="left">
                    <span className="tbtitlecb3">
                      {" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={totalThree}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Informationbox;
