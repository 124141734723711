import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import "./style.css";
import { ReactComponent as Help } from "../../assets/icn/help.svg";
import NumberFormat, { NumericFormat } from "react-number-format";
import { PieChart } from "@mui/icons-material";
import { useState } from "react";
import _ from "lodash";
import Lmark from "../../assets/icn/Lmark.svg";

const Calculatorinput = () => {
  const [salary, setSalary] = useState("");
  const [result, setResult] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const calculateExcess = (value) => {
    if (value > 308333.33) {
      return value * 0.36 - 73500;
    } else if (value > 266666.67) {
      return value * 0.3 - 55000;
    } else if (value > 225000) {
      return value * 0.24 - 39000;
    } else if (value > 183333.33) {
      return value * 0.18 - 25500;
    } else if (value > 141666.67) {
      return value * 0.12 - 14500;
    } else if (value > 100000) {
      return value * 0.06 - 6000;
    } else {
      return 0;
    }
  };

  const handleSalaryChange = (event) => {
    const inputValue = parseFloat(salary?.value);
    const result = calculateExcess(inputValue).toFixed(2); // Calculate the result
    setResult(result); // Update the result state
    setShowResult(true);
  };
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow1"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardc"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>APIT calculation</span>
          </Grid>
          <Grid
            className="cardbottom"
            container
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item md={12} xs={12} textAlign="center">
              <span className="caltit1">Enter your monthly earnings</span>
              <span className="caltit2"> (all inclusive) </span>

              <Tooltip title="Your monthly remuneration" className="tolip">
                <IconButton>
                  <Help />
                </IconButton>
              </Tooltip>

              <img src={Lmark} className="eicon" />
            </Grid>

            <Grid item md={12} xs={12} textAlign="center">
              <NumericFormat
                thousandSeparator={true}
                prefix={"Rs."}
                className="amount"
                placeholder="Rs."
                onValueChange={(values, sourceInfo) => {
                  setSalary(values);
                }}
              />
            </Grid>

            <Grid item md={12} xs={12} textAlign="center">
              <Button
                variant="contained"
                className="calbtn"
                onClick={handleSalaryChange}
                disabled={_.isEmpty(salary.value)}
              >
                Calculate
              </Button>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              {showResult ? (
                <Grid item>
                  <div className="resultbox">
                    <span className="apitt">Your APIT is</span>

                    <NumericFormat
                      displayType="text"
                      prefix={"Rs."}
                      decimalScale={2}
                      thousandSeparator={true}
                      value={result !== 0 ? result : 0}
                      className="amountt"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Calculatorinput;
