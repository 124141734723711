import { Controller, useForm } from "react-hook-form";
import Calculatorinput from "./calculatorinput";
import Informationbox from "./informationbox";
import { useEffect, useState } from "react";
import _ from "lodash";

const Combination4 = () => {
  

  return (
    <>
      <Calculatorinput/>

      <Informationbox />
    </>
  );
};

export default Combination4;
