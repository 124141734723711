import { Button, Grid } from "@mui/material"
import { HashLink as Link } from "react-router-hash-link"


const Hero = () => {

    return (

        <>

<Grid
        direction="column"
        container
        spacing={{md:6, xs:4, sm: 6}}
        justifyContent="center"
        alignItems="center"
        className="hero"
        textAlign="center"
      >
        <Grid item xs={12} md={12} className="herot">
          <span className="herotitle">Navigate APIT With Ease</span>
        </Grid>

        <Grid item xs={12} md={12} className="herot">
          <div className="parat">
            Want to know how much you'll pay in APIT this year? Whether you're
            self-assessing or handling payroll, our tool makes it
            straightforward and fast.
          </div>
        </Grid>

        <Grid item xs={12} md={12} className="herot">
         <Link to="#intro" smooth={true}> <Button variant="contained" size="large">
            Start Calculation
          </Button> </Link>
        </Grid>
      </Grid>
        </>
    )
}

export default Hero