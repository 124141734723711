import { Button, Grid } from "@mui/material";
import "./style.css";
import Del from "../../assets/icn/Dol.svg";
import Ao from "../../assets/icn/Lettemo.svg";
import Ao2 from "../../assets/icn/Lettemo2.svg";
import Ao3 from "../../assets/icn/Lettermo3.svg";
import Ao4 from "../../assets/icn/Lettermo4.svg";
import { NumericFormat } from "react-number-format";

const Informationbox = ({
  valueOne,
  valueTwo,
  valueThree,
  valueFour,
  apitOne,
  apitTwo,
  apitThree,
  TotalValue,
}) => {
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardm"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>How this is calculated</span>
          </Grid>
          <Grid
            className="cardbottomcb2"
            container
            direction="row"
            justifyContent="space-between"
            spacing={{ md: 4 }}
          >
            <Grid
              item
              md={12}
              xs={12}
              textAlign="center"
              className="maincrdcb2"
            >
              
              <Grid direction="row" spacing={4} className="inncrd">
                <Grid item md={12}>
                  <span className="inbtitle">
                  Lump sum payments include
                  </span>
                </Grid>
                <Grid 
                  item
                  md={12}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del} className="dol" />
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">Bonus & salary arrears</span>
                      <span className="cdes">
                      This includes additional payments that you receive beyond 
                      regular salary and any previously unpaid salary amounts.
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards2"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del} className="dol" />
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">Leave encashments/payouts</span>
                      <span className="cdes">
                      Receiving payment for accumulated, unused leave days such 
                      as vacation or annual leave.
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards2"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del} className="dol" />
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">
                      Medical expense reimbursements
                      </span>
                      <span className="cdes">
                      Any compensation for previously 
                      incurred medical costs such as doctor visits, 
                      prescription medications, or other healthcare-related expenses.
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards2"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del} className="dol" />
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">Employee share scheme inclusion</span>
                      <span className="cdes">
                      This includes shares employees get from special options or rights. 
                      The value is adjusted by what the employee pays for these shares.
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              
              <Grid direction="row" spacing={4} className="inncrd2cb2">
                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                  gap={4}
                >
                  <Grid item md={6} xs={12} sm={4} textAlign="left">
                    <span className="tbtitle">Earnings</span>
                  </Grid>

                  <Grid item md={2} xs={12} sm={3} textAlign="left">
                    <span className="tbtitle">Value</span>
                  </Grid>
                  <Grid item md={2} xs={6} sm={3} textAlign="left">
                    <span className="tbtitle">APIT deducted</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                  gap={{ md: 4, xs: 0, sm: 4 }}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={4}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Your total monthly earnings already paid during the year
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">0 </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={valueOne}
                      />
                    </span>
                  </Grid>

                  <Grid
                    item
                    container
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    direction="row"
                    className="mbdes"
                  >
                    <span className="tbtitle2cb2">
                      <span className="fontweight">
                        <span className="mbtextshow">APIT deducted </span>
                      </span>
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={apitOne}
                      />
                    </span>
                    <img src={Ao} className="miniicon" />
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                  gap={{ md: 4, xs: 0, sm: 4 }}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={4}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Your total monthly earnings payable during the year
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={valueTwo}
                      />
                    </span>
                  </Grid>

                  <Grid
                    item
                    container
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    direction="row"
                    className="mbdes"
                  >
                    <span className="tbtitle2cb2">
                      <span className="fontweight">
                        <span className="mbtextshow">APIT deducted </span>
                      </span>
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={apitTwo}
                      />
                    </span>
                    <img src={Ao2} className="miniicon" />
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                  gap={{ md: 4, xs: 0, sm: 4 }}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={4}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Your total lump sum payments already paid during the year
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={valueThree}
                      />
                    </span>
                  </Grid>

                  <Grid
                    item
                    container
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    direction="row"
                    className="mbdes"
                  >
                    <span className="tbtitle2cb2">
                      <span className="fontweight">
                        <span className="mbtextshow">APIT deducted </span>
                      </span>
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={apitThree}
                      />
                    </span>
                    <img src={Ao3} className="miniicon" />
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                  gap={{ md: 4, xs: 0, sm: 4 }}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={4}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Your total lump sum payments payable during the year
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={valueFour}
                      />
                    </span>
                  </Grid>

                  <Grid
                    item
                    container
                    md={2}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    direction="row"
                    className="mbdes"
                  >
                    <span className="tbtitle2cb2">Your calculated APIT</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2bt"
                  gap={{ md: 4, xs: 0, sm: 4 }}
                >
                  <Grid 
                    item
                    md={6}
                    xs={12}
                    sm={4}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                    Total estimated earnings
                    </span>
                  </Grid>

                  <Grid
                    container
                    item
                    md={4}
                    xs={12}
                    sm={3}
                    textAlign="left"
                    direction="row"
                    className="mbdes"
                  >
                    <span className="tbtitle2cb2b">
                      {" "}
                      <span className="fontweight">
                        <span className="mbtextshow">Value </span>
                      </span>{" "}
                      <NumericFormat
                        displayType="text"
                        prefix={"Rs."}
                        thousandSeparator={true}
                        value={TotalValue}
                      />
                    </span>
                    <img src={Ao4} className="miniicon" />
                  </Grid>

                  
                </Grid>
              </Grid>

              <Grid direction="row" spacing={4} className="inncrd2">
                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                >
                  <Grid item md={6} xs={12} sm={6} textAlign="left">
                    <span className="tbtitle">
                      Total estimated earnings this financial year
                    </span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={6} textAlign="left">
                    <span className="tbtitle">Tax deductible</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">Up to</span> Rs. 500,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Your APIT is </span>
                      </span>
                      (<span className="blulet"> [D] </span>x{" "}
                      <span >6% </span>) - ({" "}
                     
                      <span className="blulet2">[A]</span> +{" "}
                      <span className="blulet2">[B] </span> +
                      <span className="blulet2">[C] </span> )
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>{" "}
                      Rs. 500,001 <span className="fontweight">to</span> Rs. 1,000,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Your APIT is </span>
                      </span>
                      (<span className="blulet"> [D] </span>x{" "}
                      <span >12% </span>) - ({" "}
                      <span className="blulet">30,000</span> +{" "}
                      <span className="blulet2">[A]</span> +{" "}
                      <span className="blulet2">[B] </span>+
                      <span className="blulet2">[C] </span> )
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>{" "}
                      Rs. 1,000.001 <span className="fontweight">to</span> Rs. 1,500,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Your APIT is </span>
                      </span>
                      (<span className="blulet"> [D] </span>x{" "}
                      <span >18% </span>) - ({" "}
                      <span className="blulet">90,000</span> +{" "}
                      <span className="blulet2">[A]</span> +{" "}
                      <span className="blulet2">[B] </span> +
                      <span className="blulet2">[C] </span> )
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>{" "}
                      Rs. 1,500,001 <span className="fontweight">to</span> Rs. 2,000,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Your APIT is </span>
                      </span>
                      (<span className="blulet"> [D] </span>x{" "}
                      <span >24% </span>) - ({" "}
                      <span className="blulet">180,000</span> +{" "}
                      <span className="blulet2">[A]</span> +{" "}
                      <span className="blulet2">[B] </span> +
                      <span className="blulet2">[C] </span> )
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">You earn from </span>
                      </span>{" "}
                      Rs. 2,000,001 <span className="fontweight">to</span> Rs. 2,500,000
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Your APIT is </span>
                      </span>
                      (<span className="blulet"> [D] </span>x{" "}
                      <span >30% </span>) - ({" "}
                      <span className="blulet">300,000</span> +{" "}
                      <span className="blulet2">[A]</span> +{" "}
                      <span className="blulet2">[B] </span> +
                      <span className="blulet2">[C] </span> )
                    </span>
                  </Grid>
                </Grid>

              

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2bt"
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbtitle"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">More than</span> Rs.
                      2,500,001
                    </span>
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sm={6}
                    textAlign="left"
                    className="mbdes"
                  >
                    <span className="tbtitle2">
                      <span className="fontweight">
                        <span className="mbtextshow">Your APIT is </span>
                      </span>
                      (<span className="blulet"> [D] </span>x{" "}
                      <span >36% </span>) - ({" "}
                      <span className="blulet">450,000</span> +{" "}
                      <span className="blulet2">[A]</span> +{" "}
                      <span className="blulet2">[B] </span> +
                      <span className="blulet2">[C] </span> )
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Informationbox;
