import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import "./style.css";
import { ReactComponent as Help } from "../../assets/icn/help.svg";
import NumberFormat, { NumericFormat } from "react-number-format";
import { PieChart } from "@mui/icons-material";
import { useEffect, useState } from "react";
import _ from "lodash";
import Lmark from "../../assets/icn/Lmark.svg";
import { Controller, useForm, useWatch } from "react-hook-form";

const Calculatorinput = () => {
  const { control, handleSubmit: handleFormSubmit } = useForm();
  const [result, setResult] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [primaryAmount, setPrimaryAmount] = useState(0); // Replace with your state value
  const [secondaryAmount, setSecondaryAmount] = useState(0); // Replace with your state value

  const primaryAmountValue = useWatch({ control, name: 'primaryAmount' });
  const secondaryAmountValue = useWatch({ control, name: 'secondaryAmount' });

  const calculateAPIT = (primaryAmt, secondaryAmt) => {
    const convertToNumberOrZero = (value) =>
      _.isNaN(value) || !_.isNumber(value) ? 0 : Number(value);
    const primary = convertToNumberOrZero(primaryAmt);
    const secondary = convertToNumberOrZero(secondaryAmt);
    if (primary + secondary < 100000) {
      return 0;
    } else if (primary > 266668) {
      return secondary * 0.36;
    } else if (primary > 225001) {
      return secondary * 0.3;
    } else if (primary > 183334) {
      return secondary * 0.24;
    } else if (primary > 141668) {
      return secondary * 0.18;
    } else if (primary > 100000) {
      return secondary * 0.12;
    } else if (primary > 0) {
      return secondary * 0.06;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    const apitResult = calculateAPIT(
      Number(primaryAmount),
      Number(secondaryAmount)
    );
    setResult(apitResult.toFixed(2));
   
  }, [primaryAmount, secondaryAmount]);

  const handleSave = (data) => {
    console.log(data);
    setPrimaryAmount(data?.primaryAmount);
    setSecondaryAmount(data?.secondaryAmount);
    setShowResult(true);
  }; 
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow1"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardccb2"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>APIT calculation</span>
          </Grid>
          
          <Grid
            className="cardbottomcb4"
            container
            direction="row"
            justifyContent="space-between"
            rowSpacing={4}
          >
            <Grid item container md={12} xs={12} spacing={5}>
              

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">Your monthly earnings from your primary employment</span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  <Controller
                    name="primaryAmount"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                          console.log(values);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">
                    Your monthly earnings from your secondary employment
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                 <Controller
                    name="secondaryAmount"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                          console.log(values);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

            
            </Grid>

            <Grid item md={12} xs={12} textAlign="center" className="bttngrid">
              <Button
                variant="contained"
                className="calbtn"
                onClick={handleFormSubmit(handleSave)}
                disabled={_.isEmpty(primaryAmountValue) &&
                  _.isEmpty (secondaryAmountValue) 
                    
                 }
              >
                Calculate
              </Button>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              {showResult ? (
                <Grid item>
                  <div className="resultbox">
                    <span className="apitt">Your APIT is</span>

                    <NumericFormat
                      displayType="text"
                      prefix={"Rs."}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={result !== 0 ? result : 0}
                      className="amountt"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
    </>
  );
};

export default Calculatorinput;
