import { Controller, useForm } from "react-hook-form";
import Calculatorinput from "./calculatorinput";
import Informationbox from "./informationbox";
import { useEffect, useState } from "react";
import _ from "lodash";

const Combination7 = ({ showMessageBox, showSalaryInput }) => {
  const { control, handleSubmit: handleFormSubmit } = useForm();
  const [result, setResult] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const [annualLumpPayable, setAnnualLumpPayable] = useState(0);
  const [annualLumpSum, setAnnualLumpSum] = useState(0);
  const [annualPayable, setAnnualPayable] = useState(0);
  const [totalAnnualSalary, setTotalAnnualSalary] = useState(0);

  const [apitDeductOne, setApitDeductOne] = useState(0);
  const [apitDedutIfApplicable, setApitDedutIfApplicable] = useState(0);
  const [apitTwo, setApitTwo] = useState(0);

  const [aggrePayable, setAggrePayable] = useState(0);
  const [totalApitDeducted, setTotalApitDeducted] = useState(0);

  const sum =
    parseInt(annualLumpPayable) +
    parseInt(annualLumpSum) +
    parseInt(annualPayable) +
    parseInt(totalAnnualSalary);
    
  const calculateValue = (total1, total2) => {
    const convertToNumberOrZero = (value) =>
      _.isNaN(value) || !_.isNumber(value) ? 0 : Number(value);
    const sum = convertToNumberOrZero(total1);
    const totalApit = convertToNumberOrZero(total2);
    console.log(aggrePayable, totalApitDeducted);
    if (sum > 2500000) {
      return (sum * 0.36) - (450000 + totalApit);
  } else if (sum > 2000000) {
      return (sum * 0.30) - (300000 + totalApit);
  } else if (sum > 1500000) {
      return (sum * 0.24) - (180000 + totalApit);
  } else if (sum > 1000000) {
      return (sum * 0.18) - (90000 + totalApit);
  } else if (sum > 500000) {
      return (sum * 0.12) - (30000 + totalApit);
  } else if (sum > 0) {
      return (sum * 0.06) - totalApit;
  } else {
      return 0;
  }
  };
  const calculateTotal = (num1, num2, num3) => {
    const convertToNumberOrZero = (value) =>
      _.isNaN(value) || !_.isNumber(value) ? 0 : Number(value);
    const total =
      convertToNumberOrZero(num1) +
      convertToNumberOrZero(num2) +
      convertToNumberOrZero(num3);
    return total;
  };
  useEffect(() => {
    const total = calculateTotal(
      Number(apitDeductOne),
      Number(apitDedutIfApplicable),
      Number(apitTwo)
    );
    setTotalApitDeducted(total);
  }, [apitDeductOne, apitDedutIfApplicable, apitTwo]);
  useEffect(() => {
    const resultOne = calculateValue(
      sum,
      Number(totalApitDeducted)
    ).toFixed(2);
    setResult(resultOne);
    
  }, [sum, totalApitDeducted]);
  console.log(sum)
  useEffect(() => {
    const total = calculateTotal(
      Number(annualLumpPayable),
      Number(annualLumpSum),
      Number(annualPayable)
    );
    setAggrePayable(total);
  }, [annualLumpPayable, annualLumpSum, annualPayable]);

  // useEffect(() => {
  //   const resultOne = calculateValue(
  //     Number(aggrePayable),
  //     Number(totalApitDeducted)
  //   ).toFixed(2);
  //   setResult(resultOne);
  //   setShowResult(true);
  // }, [aggrePayable, totalApitDeducted]);

  const onSubmit = (incomingData) => {
    console.log(incomingData);
    setAnnualLumpPayable(incomingData?.annualLumpPayable || 0);
    setAnnualLumpSum(incomingData?.annualLumpSum || 0);
    setAnnualPayable(incomingData?.annualPayable || 0);
    setApitDeductOne(incomingData.apitDeductOne || 0);
    setApitDedutIfApplicable(incomingData.apitDedutIfApplicable || 0);
    setApitTwo(incomingData.apitTwo || 0);
    setTotalAnnualSalary(incomingData.totalAnnualSalary || 0);
    setShowResult(true);
  };
  return (
    <>
       <Calculatorinput
        handleSave={handleFormSubmit(onSubmit)}
        showResult={showResult}
        result={result}
        control={control}
        Controller={Controller}
      />
     <Informationbox
        valueOne={totalAnnualSalary}
        valueTwo={annualPayable}
        valueThree={annualLumpSum}
        valueFour={annualLumpPayable}
        apitOne={apitDeductOne}
        apitTwo={apitTwo}
        apitThree={apitDedutIfApplicable}
        TotalValue={sum}
      />
    </>
  );
};

export default Combination7;
