import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import "./style.css";
import { ReactComponent as Help } from "../../assets/icn/help.svg";
import NumberFormat, { NumericFormat } from "react-number-format";
import { PieChart } from "@mui/icons-material";
import { useState } from "react";
import _ from "lodash";
import Lmark from "../../assets/icn/Lmark.svg";
import { Controller, useForm, useWatch } from "react-hook-form";

const Calculatorinput = ({handleSave, control, Controller, result, showResult}) => { 

  const commutionOfpensionValue = useWatch({ control, name: 'commutionOfpension' });
  const gratuityValue = useWatch({ control, name: 'gratuity' });
  const compensationLossOfOfficeValue = useWatch({ control, name: 'compensationLossOfOffice' });
  const compensationLossOfOfficeOtherValue = useWatch({ control, name: 'compensationLossOfOfficeOther' });
  const paymentForProvidentFundValue = useWatch({ control, name: 'paymentForProvidentFund' });
  const anyOtherPaymentValue = useWatch({ control, name: 'anyOtherPayment' });
  const retirmentNonCashBenefitsValue = useWatch({ control, name: 'retirmentNonCashBenefits' });
 
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow1"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardccb2"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>APIT calculation</span>
          </Grid>
          <Grid
            className="cardbottomcb3"
            container
            direction="row"
            justifyContent="space-between"
            rowSpacing={4}
          >
            <Grid item container md={12} xs={12} spacing={5}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">Commutation of pension</span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  <Controller
                    name="commutionOfpension"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                          console.log(values);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">Gratuity</span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  {" "}
                  <Controller
                    name="gratuity"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                          console.log(values);
                          
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">
                    Compensation for loss of office
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Uniformly applicable to all employees as considered by CGIR"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  <Controller
                    name="compensationLossOfOffice"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                         
                          
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">
                    Withdrawal of ETF
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip title="Excluding investment income earned after 01/04/1897" className="tolip">
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                 

                      <Controller
                    name="compensationLossOfOfficeOther"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                          console.log(values);
                        }}
                      />

                      
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <div className="calborder3"></div>
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">Compensation for loss of office (other)</span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Other than above"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  

                      <Controller
                      name="compensationLossOfOfficeOther"
                      control={control}
                      render={({ field }) => (
                        <NumericFormat
                          thousandSeparator={true}
                       
                          prefix={"Rs."}
                          className="amount"
                          placeholder="Rs."
                          onValueChange={(values, sourceInfo) => {
                            field.onChange(values.value);
                            console.log(values);
                          }}
                        />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">
                      Payment for provident fund
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip title="Not approved by CGIR" className="tolip">
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  <Controller
                    name="paymentForProvidentFund"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                          console.log(values);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">Any other payment</span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  <Controller
                    name="anyOtherPayment"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid container md={6} xs={12}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    className="aptcaltitle"
                  >
                    <span className="caltit1cb2">
                      Retirement non cash benefits
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolipcb2"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid
                  container
                  md={6}
                  xs={12}
                  justifyContent="flex-end"
                  className="aptcal"
                >
                  <Controller
                    name="retirmentNonCashBenefits"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12} textAlign="center" className="bttngrid">
              <Button
                variant="contained"
                className="calbtn"
                onClick={handleSave}
                disabled={_.isEmpty(commutionOfpensionValue) &&
                  _.isEmpty (gratuityValue) &&
                  _.isEmpty (compensationLossOfOfficeValue) &&
                  _.isEmpty (compensationLossOfOfficeOtherValue) &&
                  _.isEmpty (paymentForProvidentFundValue) &&
                  _.isEmpty (anyOtherPaymentValue) &&
                  _.isEmpty (retirmentNonCashBenefitsValue) 
                    
                 }
              >
                Calculate
              </Button>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              {showResult ? (
                <Grid item>
                  <div className="resultbox">
                    <span className="apitt">Your APIT is</span>

                    <NumericFormat
                      displayType="text"
                      prefix={"Rs."}
                      thousandSeparator={true}
                      decimalScale={2}
                      value={result !== 0 ? result : 0}
                      className="amountt"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Calculatorinput;
