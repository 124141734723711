import { Button, Grid } from "@mui/material";
import "./style.css";
import Del from "../../assets/icn/Dol.svg";

const Informationbox = ({ showMessageBox }) => {
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardm"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>How this is calculated</span>
          </Grid>
          <Grid
            className="cardbottom"
            container
            direction="row"
            justifyContent="space-between"
            spacing={{md:4,}}
          >
            <Grid item md={12} xs={12} textAlign="center" className="maincrd" >
              <Grid direction="row" spacing={4} className="inncrd">
                <Grid item md={12}>
                  <span className="inbtitle">
                    Your monthly earnings include
                  </span>
                </Grid>
                <Grid
                  item
                  md={12}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del} className="dol"/>
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">Earnings from work</span>
                      <span className="cdes">
                        This includes your monthly salary, extra pay for working
                        overtime, commissions, and travel costs. It also covers
                        any fees or other benefits you get from your job during
                        a pay cycle.
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards2"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del}  className="dol"/>
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">Medical expenses</span>
                      <span className="cdes">
                        Costs for medical treatments and insurance. This doesn't
                        include any reimbursements or disbursements for dental
                        or health expenses that are given equally to all
                        full-time workers.
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards2"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del}  className="dol"/>
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">
                        Living and lifestyle expenses
                      </span>
                      <span className="cdes">
                        Costs related to your home, transportation, electricity,
                        phone bills, and entertainment activities.
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className="dolcards2"
                  >
                    <Grid
                      item
                      md={2}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <img src={Del}  className="dol"/>
                    </Grid>

                    <Grid
                      item
                      md={10}
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <span className="ctitle">Payments for others</span>
                      <span className="cdes">
                        Money given to someone else, either for your benefit or
                        for someone associated with you.
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid direction="row" spacing={4} className="inncrd2">
                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg"
                >
                  <Grid item md={6} xs={12} sm={7} textAlign="left">
                    <span className="tbtitle">
                     How much you earn every month (E)
                    </span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={5} textAlign="left">
                    <span className="tbtitle">
                    Your APIT
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid item md={6} xs={12} sm={7}  textAlign="left" className="mbtitle">
                    <span className="tbtitle2"><span className="fontweight"><span className="mbtextshow">You earn </span>Up to</span> Rs. 41,667</span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={5}  textAlign="left" className="mbdes">
                    <span className="tbtitle2"><span className="fontweight"><span className="mbtextshow">Your APIT is </span></span>6%</span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid item md={6} xs={12} sm={7} textAlign="left" className="mbtitle">
                    <span className="tbtitle2"><span className="fontweight"><span className="mbtextshow">You earn from </span></span>Rs. 41,668 <span className="fontweight">to</span> Rs. 83,333</span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={5} textAlign="left" className="mbdes">
                    <span className="tbtitle2">
                    <span className="fontweight"><span className="mbtextshow">Your APIT is </span></span><span className="blulet">[E]</span> x 12% - 2,500
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid item md={6} xs={12} sm={7} textAlign="left" className="mbtitle" >
                    <span className="tbtitle2"><span className="fontweight"><span className="mbtextshow">You earn from </span></span>Rs. 83,334 <span className="fontweight">to</span> Rs. 125,000</span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={5} textAlign="left" className="mbdes">
                    <span className="tbtitle2">
                    <span className="fontweight"><span className="mbtextshow">Your APIT is </span></span><span className="blulet">[E]</span> x 18% - 7,500
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid item md={6} xs={12} sm={7} textAlign="left" className="mbtitle">
                    <span className="tbtitle2"><span className="fontweight"><span className="mbtextshow">You earn from </span></span>Rs. 125,001 <span className="fontweight">to</span> Rs. 166,667</span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={5} textAlign="left" className="mbdes">
                    <span className="tbtitle2">
                    <span className="fontweight"><span className="mbtextshow">Your APIT is </span></span><span className="blulet">[E]</span> x 24% - 15,000
                    </span>
                  </Grid>
                </Grid>

                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid item md={6} sm={7}xs={12} textAlign="left" className="mbtitle">
                    <span className="tbtitle2"><span className="fontweight"><span className="mbtextshow">You earn from </span></span>Rs. 166,668 <span className="fontweight">to</span> Rs. 208,333</span>
                  </Grid>

                  <Grid item md={6} sm={5} xs={12} textAlign="left" className="mbdes">
                    <span className="tbtitle2">
                    <span className="fontweight"><span className="mbtextshow">Your APIT is </span></span><span className="blulet">[E]</span> x 30% - 25,000
                    </span>
                  </Grid>
                </Grid>

              
                <Grid
                  item
                  md={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className="tbtitlebg2"
                >
                  <Grid item md={6} xs={12} sm={7} textAlign="left" className="mbtitle">
                    <span className="tbtitle2"><span className="fontweight">More than</span> Rs. 208,333</span>
                  </Grid>

                  <Grid item md={6} xs={12} sm={5} textAlign="left" className="mbdes">
                    <span className="tbtitle2">
                    <span className="fontweight"><span className="mbtextshow">Your APIT is </span></span><span className="blulet">[E]</span> x 36% - 37,500
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Informationbox;
