import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./style.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <Container maxWidth="md">
        <Grid container justifyContent="center" alignItems="center" textAlign="left">
          <Grid item xs={12}>
            <div className="privacy-policy-content">
              <h1>Privacy and Data Protection Policy</h1>
              
              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Typography><span className="accTitle">Introduction</span></Typography>
                </AccordionSummary>
                <AccordionDetails >
                  <Typography>
                    This Privacy and Data Protection Policy sets out the practices that Divit Advisory (Pvt) Ltd (“Divit Advisory”, “Company”, “we”, “our”, “us”) follow with respect to the collection, use, maintenance, transfer, and disclosure of information collected when each person (“you”, “your”) who use the Adayama website located at <a href="https://adayama.lk">https://adayama.lk</a> (“Website”) and the service provided therein by the Divit Advisory.
                    We respect and value the privacy of each person (“you” or “your”) who enter our Website and we commit towards maintaining the highest standards required in order to protect any personal information you consent to share with us in accordance with the Personal Data Protection Act No. 09 of 2022 (the “Act”). This Privacy Policy applies only to information we collect from the Website (<a href="https://adayama.lk">https://adayama.lk</a>) and does not apply to any other information collected offline or via channels other than this Website.
                    The purpose of this policy is to provide you with an understanding of how we collect, handle, and use your personal data.
                    Divit Advisory collects personal data about you when you (1) register for an account on our Website, (2) use our products, (3) sign up for our newsletter, (4) subscription, (5) communicates with us through any mode of communication including, but not limited to, chat, phone and email.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">1. Information we collect</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to adayama.lk. The information that we request will be retained by us and used as described in this privacy policy.
                    <div className="accTitlechild">i. Client Information</div>
                    You may provide us with the following personal information when you choose to register yourself with our Website.<br/><br/>When you use our service through the Website, it will prompt you to provide us with information such as your name, email address, contact details, nationality, geographic region, employment, financial information such as your earnings, bank balances, investment, assets or other types of data. As part of our commitment to the utmost security and privacy, we want to emphasize that any sensitive information that you provided to us will be handled with the highest level of confidentiality.<br/><br/>Your personal information and other sensitive data are securely stored in our system, which employs robust encryption and access controls. Our team follows strict security protocols to ensure that only authorized personnel have access to this information, and it is never shared or disclosed to any unauthorized parties.
                    <div className="accTitlechild">ii. Usage Information</div>
                    We may collect “usage information” anytime you visit, view and/or interact with our Website. We may collect your computer’s IP address, browser type, operating system version, geolocation information, cookies, IP address, information regarding your browsing activities (e.g., parts of the website visited, items clicked on) and any other server log information. We may associate this information with your personally identifiable information.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">2. How We Use Your Information</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Divit Advisory collects personal data for some or all of the following reasons:
                    <ul>
                      <li>To respond to your inquiries, provide you with information about our products and services, and personalize your experience on our Website.</li>
                      <li>To contact you, and to notify you about important developments to the Website or our service.</li>
                      <li>To assist in the operation of our Website and to ensure delivery of the services you need and request.</li>
                      <li>To provide, operate, and maintain our Website.</li>
                      <li>To improve, personalize, and enhance your experience on usage of our website, including managing and recording your preferences.</li>
                      <li>To understand and analyze how you use our website.</li>
                      <li>To develop new products, services, features, and functionality, marketing and research purposes.</li>
                      <li>To communicate with you, either directly or through one of our partners, including for customer service.</li>
                      <li>To provide you with updates and other information relating to the website, and for marketing and promotional purposes.</li>
                      <li>To process an application as requested by you.</li>
                      <li>To comply with our legal obligations, respond to legal process requests, or requests from law enforcement bodies or other third parties, including under relevant laws, such as the Personal Data Protection Act in Sri Lanka.</li>
                      <li>To make sure you can use the products we provide through our platforms, according to applicable terms and conditions.</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">3. Disclosure to Third-Party</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We do not sell, trade or otherwise transfer to third parties your personally identifiable information. Any information provided to the Website will be protected from loss, misuse, unauthorized access or disclosure, alteration or destruction.
                    However, we share your personal information with authorized third parties who assist us in operating our Website, conducting our business or servicing you including, but not limited to online support, as long as these parties are compliant with applicable data protection laws.
                    We may choose to retain your personal information to the full extent of any period provided by law and statute. We may also choose to delete your personal information from our databases after a required number of years and/or if the intended purposes for which we have collected your information is completed or is no longer relevant. We may release your information when we are required to comply with the law, enforce our policies, or protect Divit Advisory’s and other third-party rights, property or safety. However, non-personally identifiable information may be provided by Divit Advisory to third parties for marketing, advertising, or other uses.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">4. Company policy towards minors</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Our Website is not intended for children under the age of 13. We do not intentionally collect personally identifiable information from users of this Site who are under the age of 13 through this Website. If you think that we have collected personal information from a child under the age of 13 through this Website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">5. Threat Assessment and Protection</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We have standardized enterprise-wide analysis of Website-related threats within the organization and have prioritized proactive improvement of threat coverage throughout the organization to ensure that there are no leaks of the data we collect.
                    We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place the following suitable physical, electronic and managerial procedures to secure the information:
                    <ol type="a">
                      <li>Implement strong access controls, including strong passwords, input validations, multi-factor authentication, and role-based access permissions.</li>
                      <li>Use secure protocols (SSL/TLS version 1.2 and higher) for data transmission and avoid transmitting sensitive data over unsecured networks.</li>
                      <li>Establish clear data retention policies and securely dispose of data that is no longer needed.</li>
                      <li>Divit Advisory is keeping systems and Website up to date with the latest security patches.</li>
                      <li>Conduct regular security audits, GDPR compliances, and assessments to identify vulnerabilities and implement necessary measures.</li>
                      <li>Encrypted the offline data stored on the device using strong encryption algorithms. Android provides cryptographic APIs and libraries (AES).</li>
                      <li>Established secure key management techniques to store and handle encryption keys (API keys).</li>
                      <li>Data protection by design and default.</li>
                    </ol>
                    <ol type="i" className="romanChild">
                      <li>Monitoring and reporting in a file any unauthorized or illegal access attempts.</li>
                      <li>Monitoring specific activities such as who accesses personal data and with whom the data are being shared.</li>
                      <li>Keeping a record of how long the data are to be stored while being stored.</li>
                      <li>The data are encrypted, pseudonymized, and anonymized whenever possible, to protect them from any unauthorized access.</li>
                      <li>The components are following industry standards GDPR and acquiring a security certification.</li>
                    </ol>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">6. Storage and Retention</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We may keep information and content in our systems, backup files and archives as follows:
                    <ol type="i">
                      <li>The Company implement AES-256 encryption for both data at rest and in transit. Use TLS 1.2 or higher for secure data transmission. Ensure robust key management practices, employing hardware security modules (HSMs) or key management services (KMS).</li>
                      <li>The Company perform daily automated backups and conduct regular restore tests. Use off-site backup storage to enhance disaster recovery capabilities.</li>
                      <li>The Company implement a comprehensive Security Information and Event Management (SIEM) system to centralize and analyze logs from various sources. Use real-time monitoring and alerts for suspicious activities. Maintain a 24/7 Security Operations Center (SOC).</li>
                      <li>The Company conducts regular reviews of data classification policies. Apply additional security measures such as pseudonymization and anonymization for highly sensitive data.</li>
                      <li>The Company establish a secure data disposal policy, including methods such as data wiping and physical destruction of storage media. Ensure compliance with GDPR requirements for data erasure.</li>
                      <li>The Company collects and processes only necessary data. Regularly review data collection practices to avoid excess. Anonymize or pseudonymize personal data where possible to reduce breach impact.</li>
                    </ol>
                    We will retain your personal data for as long as necessary to provide you with the services requested or unless you expressly request the deletion of your personal data or for the duration the legislation permits.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">7. Your Rights.</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We understand your rights to access, update, delete, restrict processing or make any complaints about the personal information that we have collected from you. You may exercise these requests by using our web contact form. In your request, please make it clear what information you would like to have changed. For your protection, we may need to verify your identity before implementing your request. We will try to implement your request as soon as reasonably practicable. We reserve the right to refuse to act on a request that is manifestly unfounded or excessive (for example because it is repetitive).
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">8. Changes to this Privacy Policy</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    We reserve the right to revise this Privacy Policy at any time, without prior notice, and the changes will be effective subsequent to posting the changes to the Website. Please check our Website frequently to be updated on the changes. By accessing the content and services made available on the Website and by providing your personal data to us, you acknowledge and agree that you have fully read and understood this policy, and consent to the collection, use, processing, and disclosure of your personal data as described in this policy. If you do not want the Divit Advisory to collect, use, store, transmit or display collected data, please do not install, subscribe or use the Website.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className="accBoxShadow" >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography><span className="accTitle">9. How to Contact Us</span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    If you have any questions or concerns regarding the Privacy Policy, please feel free to contact us at the following email or telephone number;
                    <p>Email: <a href="mailto:tax@divitadvisory.com">tax@divitadvisory.com</a></p>
                    <p>Phone: <a href="tel:+94 112 699 114">+94 112 699 114</a></p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="footer">
        <Container maxWidth="xl">
          <Footer />
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
