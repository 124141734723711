
import Calculatorinput from "./calculatorinput";
import Informationbox from "./informationbox";

const Combination1 =({showMessageBox, showSalaryInput})=> {
    
    
  return (
      <>
     
     <Calculatorinput />

     <Informationbox />
  

  
      </>
    );
  }
  
  export default Combination1;