import Calculatorinput from "./calculatorinput";
import Informationbox from "./informationbox";

const Combination5 = ({ showMessageBox, showSalaryInput }) => {
  return (
    <>
      <Calculatorinput />

      <Informationbox />
    </>
  );
};

export default Combination5;
