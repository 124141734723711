import React from "react";
import Header from "../../components/header";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/footer";
import "./style.css"
import Line from "../../assets/img/csanimation.json"
import Lottie from "lottie-react"

const ComingSoon = () => {
  return (
    <>
      {<Header />}

      <Container maxWidth="xl">
        <Grid Container className="commingsoon" justifyContent="center" alignItems="center" textAlign="center">
 
        <Grid item >

          <Lottie animationData={Line} className="animation"/>
          <span className="cstitle"  >Coming Soon</span>

          </Grid>

        </Grid>
      </Container>

      <div className="footer">
        <Container maxWidth="xl">
          <Footer />
        </Container>
      </div>
    </>
  );
};

export default ComingSoon;
