import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import "./style.css";
import { ReactComponent as Help } from "../../assets/icn/help.svg";
import NumberFormat, { NumericFormat } from "react-number-format";
import { PieChart } from "@mui/icons-material";
import { useState } from "react";
import _ from "lodash";
import Lmark from "../../assets/icn/Lmark.svg";
import { useWatch } from "react-hook-form";

const Calculatorinput = ({handleSave, control, Controller, result, showResult}) => {

  const annualLumpPayableValue = useWatch({ control, name: 'annualLumpPayable' });
  const totalAnnualSalaryValue = useWatch({ control, name: 'totalAnnualSalary' });
  const apitDeductOneValue = useWatch({ control, name: 'apitDeductOne' });
  const annualPayableValue = useWatch({ control, name: 'annualPayable' });
  const apitTwoValue = useWatch({ control, name: 'apitTwo' });
  const annualLumpSumValue = useWatch({ control, name: 'annualLumpSum' });
  const apitDedutIfApplicableValue = useWatch({ control, name: 'apitDedutIfApplicable' });



  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow1"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardccb2"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>APIT calculation</span>
          </Grid>
          <Grid
            className="cardbottomcb2"
            container
            direction="row"
            justifyContent="space-between"
            rowSpacing={4}
          >
            <Grid item container md={12} xs={12} spacing={5}>
              <Grid item container justifyContent="space-between" alignItems="center" direction="row">
                
                <Grid container md={6} xs={12} >
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2">
                    Your total monthly earnings already paid during the year
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolipcb2"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="totalAnnualSalary"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                    
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>


              <Grid item container justifyContent="space-between" alignItems="center" direction="row">
                
                <Grid container md={6} xs={12} >
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2or">
                    APIT deducted
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (You may use the monthly salary APIT calculator if you don't know this value) </span>

                   
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="apitDeductOne"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        
                        prefix={"Rs."}
                        className="amount2"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <div className="calborder3"></div>
                </Grid>
              </Grid>

              <Grid item container justifyContent="space-between" alignItems="center" direction="row" >
                
                <Grid container md={6} xs={12}>
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2">
                    Your total monthly earnings payable during the year
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="annualPayable"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                        
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

             
              <Grid item container justifyContent="space-between" alignItems="center" direction="row">
                
                <Grid container md={6} xs={12} >
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2or">
                    APIT deducted
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (You may use the monthly salary APIT calculator if you don't know this value) </span>

                   
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="apitTwo"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                      
                        prefix={"Rs."}
                        className="amount2"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <div className="calborder3"></div>
                </Grid>
              </Grid>

              <Grid item container justifyContent="space-between" alignItems="center" direction="row">
                
                <Grid container md={6} xs={12}>
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2">
                    Your total lump sum payments already paid during the year
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="annualLumpSum"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                       
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item container justifyContent="space-between" alignItems="center" direction="row">
                
                <Grid container md={6} xs={12} >
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2or">
                    APIT deducted (if applicable)
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (State any lump sum APIT already paid this year) </span>

                   
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="apitDedutIfApplicable"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                       
                        prefix={"Rs."}
                        className="amount2"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <div className="calborder3"></div>
                </Grid>
              </Grid>

              <Grid item container justifyContent="space-between" alignItems="center" direction="row">
                
                <Grid container md={6} xs={12}>
                  <Grid container justifyContent="flex-start" className="aptcaltitle">
                    <span className="caltit1cb2">
                    Your total lump sum payments payable during the year
                    </span>
                  </Grid>

                  <Grid container direction="row" className="aptcaltitle2">
                    <span className="caltit2cb2"> (April to March) </span>

                    <Tooltip
                      title="Your monthly remuneration"
                      className="tolip"
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container md={6} xs={12} justifyContent="flex-end" className="aptcal">
                <Controller
                    name="annualLumpPayable"
                    control={control}
                    render={({ field }) => (
                      <NumericFormat
                        thousandSeparator={true}
                      
                        prefix={"Rs."}
                        className="amount"
                        placeholder="Rs."
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            
            </Grid>

            <Grid item md={12} xs={12} textAlign="center" className="bttngrid">
              <Button
                variant="contained"
                className="calbtn"
                onClick={handleSave}
                disabled={_.isEmpty(annualLumpPayableValue) &&
                _.isEmpty (totalAnnualSalaryValue) &&
                _.isEmpty (apitDeductOneValue) &&
                _.isEmpty (annualPayableValue) &&
                _.isEmpty (apitTwoValue) &&
                _.isEmpty (annualLumpSumValue) &&
                _.isEmpty (apitDedutIfApplicableValue) 
                  
               }

                  >
                Calculate
              </Button>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              textAlign="center"
              alignItems="center"
              justifyContent="center"
            >
              {showResult ? (
                <Grid item>
                  <div className="resultbox">
                    <span className="apitt">Your APIT is</span>

                    <NumericFormat
                      displayType="text"
                      decimalScale={2}
                      prefix={"Rs."}
                      thousandSeparator={true}
                      value={result !== 0 ? result : 0}
                      className="amountt"
                    />
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Calculatorinput;
