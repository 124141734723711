import { Button, Grid } from "@mui/material";
import "./style.css";
import Del from "../../assets/icn/Dol.svg";
import Ao from "../../assets/icn/Lettemo.svg";
import Ao2 from "../../assets/icn/Lettemo2.svg";
import Ao3 from "../../assets/icn/Lettermo3.svg";
import Ao4 from "../../assets/icn/Lettermo4.svg";

const Informationbox = ({ showMessageBox }) => {
  return (
    <>
      <Grid
        Grid
        item
        xs={12}
        md={12}
        className="mainrow"
        direction="column"
        alignItems="stretch"
      >
        <Grid
          className="maincardm"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className="cardtop" textAlign="left">
            <span>How this is calculated</span>
          </Grid>
          <Grid
            className="cardbottomcb2"
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid
              container
              md={12}
              xs={12}
              textAlign="center"
              className="maincrdcb2"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                alignItems="stretch"
                gap={2}
                justifyContent="center"
              >
                <Grid item className="textbox1" md={4} xs={12} sm={6}>
                  <div>
                    Your monthly earnings from your secondary employment
                  </div>
                </Grid>

                <Grid item className="xtext" alignItems="center">
                  <div>X</div>
                </Grid>
                <Grid item className="textbox2">
                  <div>36%</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Informationbox;
