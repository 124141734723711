import { Grid } from '@mui/material'
import React from 'react'
import "./style.css"

const OtherCombination = () => {
  return (
    <>

    <Grid container justifyContent="center" alignItems="center" className='errmain'>


        <Grid item Container justifyContent="center" alignItems="center">

            <span className='errmessege'>
            APIT doesn't exist for this selection. Please try a different selection.
            </span>
        </Grid>


    </Grid>
    
    </>
  )
}

export default OtherCombination
